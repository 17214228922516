<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-80">
        <form @submit.prevent="submit">
          <md-card>
            <md-card-header :data-background-color="dataBackgroundColor">
              <h4 class="title">Dodaj nową kategorię</h4>
              <p class="category"></p>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field :class="{ 'md-invalid': $v.name.$error }">
                    <label>Nazwa</label>
                    <md-input v-model="name" type="text"></md-input>
                    <span class="md-error md-size-100" v-if="!$v.name.required">Nazwa kategorii jest wymagana</span>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field :class="{ 'md-invalid': $v.description.$error }">
                    <label>Opis kategorii</label>
                    <md-textarea v-model="description" type="text"></md-textarea>
                    <span class="md-error md-size-100" v-if="!$v.description.required">Opis kategorii jest wymagany</span>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field>
                    <label>Slug (niewymanage)</label>
                    <md-input v-model="slug" type="text"></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field :class="{ 'md-invalid': $v.icon.$error }">
                    <label>Ikona</label>
                    <md-file v-model="icon" />
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field>
                    <label for="parent">Kategoria nadrzędna</label>
                    <md-select v-model="parent" name="parent" id="parent">
                      <md-option value="">-- Brak --</md-option>
                      <md-option v-for="item in categories" :key="item.id" :value="item.id">
                        {{ item.name }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>

                <div class="md-layout-item md-size-100 text-right">
                  <md-button class="md-raised md-success" type="submit" @click="handleSubmit()">Utwórz</md-button>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "CategoriesAdd",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "green",
    },
  },
  data() {
    return {
      categories: [],
      name: "",
      slug: "",
      description: "",
      icon: null,
      parent: "",
      submitStatus: null,
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      return await this.$axios
        .get("/categories/all-categories")
        .then((res) => {
          this.categories = res.data;
        })
        .catch((error) => {
          this.categories = [];
        });
    },
    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        this.createCategory();
      }
    },
    async createCategory() {
      const category = {
        name: this.name,
        slug: this.slug,
        description: this.description,
        icon: this.icon || undefined,
        parentId: String(this.parent) || undefined,
      };
      return await this.$axios
        .post(`/categories/`, category, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((results) => {
          this.clearFields();
          this.$router.push({ path: "/categories" });
          this.submitStatus = "OK";
        })
        .catch((err) => {
          this.submitStatus = "ERROR";
        });
    },
    clearFields() {
      this.name = "";
      this.slug = "";
      this.description = "";
      this.icon = null;
      this.parent = "";
    },
  },
  validations: {
    name: {
      required,
    },
    description: {
      required,
    },
    slug: {},
    icon: {},
    parent: {},
  },
};
</script>

<style scoped></style>
