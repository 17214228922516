<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Zapisani do newsletter'a</h4>
          </md-card-header>
          <md-card-content>
            <md-table v-model="newsletter">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
                <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>
                <md-table-cell md-label="Akcje">
                  <md-button class="md-primary" @click="deleteNewsletter(item.id)">Usuń</md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
      <!-- <div class="md-layout-item md-size-100">
        
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsletterList",
  data() {
    return {
      newsletter: [],
    };
  },
  mounted() {
    this.getNewsletterList();
  },
  methods: {
    async getNewsletterList() {
      return await this.$axios
        .get(`/newsletter/all`)
        .then((res) => {
          this.newsletter = res.data;
        })
        .catch((error) => {
          this.newsletter = [];
        });
    },
    async deleteNewsletter(id) {
      return await this.$axios
        .delete(`/newsletter/${id}`)
        .then((res) => {
          this.getNewsletterList();
        })
        .catch((error) => {
          this.getNewsletterList();
        });
    },
  },
};
</script>
