<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Wiadomości z formularza kontaktowego</h4>
          </md-card-header>
          <md-card-content>
            <md-table v-model="list">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
                <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>
                <md-table-cell md-label="Temat">{{ item.subject }}</md-table-cell>
                <md-table-cell md-label="Przeczytane">{{ item.isRead ? "tak" : "nie" }}</md-table-cell>
                <md-table-cell md-label="Akcje">
                  <router-link :to="`/contact/${item.id}`">
                    <md-button class="md-primary">Zobacz</md-button>
                  </router-link>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
      <!-- <div class="md-layout-item md-size-100">
        
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactList",
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getContactMessages();
  },
  methods: {
    async getContactMessages() {
      return await this.$axios
        .get(`/contact`)
        .then((res) => {
          this.list = res.data;
        })
        .catch((error) => {
          this.list = [];
        });
    },
  },
};
</script>
