import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";

import Dashboard from "@/pages/Dashboard.vue";
import UserProfile from "@/pages/users/UserProfile.vue";
import TableList from "@/pages/TableList.vue";
import Categories from "@/pages/categories/Categories.vue";
import CategoriesList from "@/pages/categories/CategoriesList.vue";
import CategoriesAdd from "@/pages/categories/CategoriesAdd.vue";
import CategoriesEdit from "@/pages/categories/CategoriesEdit.vue";
import DiscountCodes from "@/pages/discount-codes/DiscountCodes.vue";
import Users from "@/pages/users/Users.vue";
import UsersAdd from "@/pages/users/UsersAdd.vue";
import UsersEdit from "@/pages/users/UsersEdit.vue";
import Login from "@/pages/Login.vue";
import DiscountCodesList from "../pages/discount-codes/DiscountCodesList.vue";
import DiscountCodesAdd from "../pages/discount-codes/DiscountCodesAdd.vue";
import DiscountCodesEdit from "../pages/discount-codes/DiscountCodesEdit.vue";
import Newsletter from "../pages/newsletter/Newsletter.vue";
import NewsletterList from "../pages/newsletter/NewsletterList.vue";
import Contact from "../pages/contact/Contact.vue";
import ContactList from "../pages/contact/ContactList.vue";
import ContactDetails from "../pages/contact/ContactDetails.vue";
import Cooperation from "../pages/cooperation/Cooperation.vue";
import CooperationList from "../pages/cooperation/CooperationList.vue";
import CooperationDetails from "../pages/cooperation/CooperationDetails.vue";
import Orders from "../pages/orders/Orders.vue";
import OrdersList from "../pages/orders/OrdersList.vue";
import OrderDetails from "../pages/orders/OrderDetails.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/orders",
    children: [
      {
        path: "user",
        name: "User Profile",
        component: UserProfile,
      },
      // {
      //   path: "users",
      //   component: Users,
      //   children: [
      //     { path: "", name: "Użytkownicy", component: TableList },
      //     { path: "add", name: "Dodaj użytkownika", component: UsersAdd },
      //     { path: ":id", name: "Edytuj użytkownika", component: UsersEdit },
      //   ],
      // },
      {
        path: "orders",
        component: Orders,
        children: [
          { path: "", name: "Zamówienia", component: OrdersList },
          { path: ":id", name: "Szczegóły zamówienia", component: OrderDetails },
        ],
      },
      {
        path: "categories",
        component: Categories,
        children: [
          { path: "", name: "Kategorie", component: CategoriesList },
          { path: "add", name: "Dodaj kategorię", component: CategoriesAdd },
          { path: ":id", name: "Edytuj kategorię", component: CategoriesEdit },
        ],
      },
      {
        path: "discount-codes",
        component: DiscountCodes,
        children: [
          { path: "", name: "Kody rabatowe", component: DiscountCodesList },
          { path: "add", name: "Dodaj kod rabatowy", component: DiscountCodesAdd },
          { path: ":id", name: "Edytuj kod rabatowy", component: DiscountCodesEdit },
        ],
      },
      {
        path: "newsletter",
        component: Newsletter,
        children: [{ path: "", name: "Newsletter", component: NewsletterList }],
      },
      {
        path: "contact",
        component: Contact,
        children: [
          { path: "", name: "Kontakt", component: ContactList },
          { path: ":id", name: "Wiadomość - kontakt", component: ContactDetails },
        ],
      },
      {
        path: "cooperation",
        component: Cooperation,
        children: [
          { path: "", name: "Współpraca", component: CooperationList },
          { path: ":id", name: "Wiadomość - współpraca", component: CooperationDetails },
        ],
      },
    ],
  },
];

export default routes;
