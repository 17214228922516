<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Wiadomości z formularza kontaktowego</h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-size-100"><b>ID:</b> {{ data.id }}</div>
              <div class="md-layout-item md-size-100"><b>Imię:</b> {{ data.firstName }}</div>
              <div class="md-layout-item md-size-100"><b>Nazwisko:</b> {{ data.lastName }}</div>
              <div class="md-layout-item md-size-100"><b>Email:</b> {{ data.email }}</div>
              <div class="md-layout-item md-size-100"><b>Numer telefonu:</b> {{ data.phone }}</div>
              <div class="md-layout-item md-size-100"><b>Kraj działalności:</b> {{ data.businessCountry }}</div>
              <div class="md-layout-item md-size-100">
                <b>Aktualnie spółpracuje z inną marką badań:</b> {{ data.currentPartnershipWithOtherBrands ? "tak" : "nie" }}
              </div>
              <div class="md-layout-item md-size-100"><b>Profil/strona internetowa:</b> {{ data.websiteUrl }}</div>
              <div class="md-layout-item md-size-100"><b>Wysłane:</b> {{ new Date(data.createdAt).toLocaleString() }}</div>
              <div class="md-layout-item md-size-100">
                <md-checkbox :value="!data.isRead" @change="toggleReadStatus">
                  {{ data.isRead ? "Przeczytane" : "Nieprzeczytane" }}
                </md-checkbox>
              </div>
              <div class="md-layout-item md-size-100">
                <md-button class="md-primary" @click="deleteContactMessage()">Usuń</md-button>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
      <!-- <div class="md-layout-item md-size-100">
        
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "CooperationDetails",
  data() {
    return {
      data: null,
    };
  },
  mounted() {
    this.getCooperationDetails();
  },
  methods: {
    async getCooperationDetails() {
      return await this.$axios
        .get(`/cooperation/${this.$route.params.id}`)
        .then((res) => {
          this.data = res.data;
        })
        .catch((error) => {
          this.data = null;
        });
    },
    async toggleReadStatus() {
      return await this.$axios
        .put(`/cooperation/change-read-status/${this.$route.params.id}`, {
          isRead: !this.data.isRead,
        })
        .then((res) => {
          this.getCooperationDetails();
        })
        .catch((error) => {
          this.getCooperationDetails();
        });
    },
    async deleteContactMessage() {
      return await this.$axios
        .delete(`/cooperation/${this.$route.params.id}`)
        .then((res) => {
          this.$router.push({ path: "/cooperation" });
        })
        .catch((error) => {
          this.getCooperationDetails();
        });
    },
  },
};
</script>
