<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="orange">
          <template slot="header">
            <md-icon>content_copy</md-icon>
          </template>

          <template slot="content">
            <p class="category">Ilość użytkowników</p>
            <h3 class="title">
              {{ this.userAmount }}
            </h3>
          </template>
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { StatsCard, ChartCard } from "@/components";

export default {
  components: {
    StatsCard,
  },
  data() {
    return {
      userAmount: 0,
    };
  },
  mounted() {
    this.getUsersAmount();
  },
  methods: {
    async getUsersAmount() {
      return await axios
        .get(process.env.VUE_APP_USERS_URL)
        .then((response) => {
          this.userAmount = response.data.Users.length;
        })
        .catch((error) => {
          this.userAmount = 0;
        });
    },
  },
};
</script>
