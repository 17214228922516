<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card class="md-card-plain">
          <md-card-header data-background-color="green">
            <h4 class="title">Użytkownicy</h4>
            <p class="category"></p>
          </md-card-header>
          <md-card-content>
            <router-link to="/users/add">
              <md-button class="md-info">Dodaj użytkownika</md-button>
            </router-link>
          </md-card-content>
          <md-card-content>
            <users-table></users-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { UsersTable } from "@/components";
export default {
  components: {
    UsersTable,
  },
};
</script>
