<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-80">
        <form @submit.prevent="submit">
          <md-card>
            <md-card-header :data-background-color="dataBackgroundColor">
              <h4 class="title">Edytuj kod rabatowy</h4>
              <p class="category"></p>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field :class="{ 'md-invalid': $v.code.$error }">
                    <label>Kod</label>
                    <md-input v-model="code" type="text"></md-input>
                    <span class="md-error md-size-100" v-if="!$v.code.required">Kod rabatowy jest wymagany</span>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field :class="{ 'md-invalid': $v.discount.$error }">
                    <label>Wartość rabatu</label>
                    <md-input v-model="discount" type="text"></md-input>
                    <span class="md-error md-size-100" v-if="!$v.discount.required">Wartość rabatu jest wymagana</span>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-checkbox v-model="isPercentage">Wartość procentowa</md-checkbox>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-checkbox v-model="isUnlimited">Nieskończone użycia?</md-checkbox>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <p>Użycia: {{ uses }}</p>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <p>
                    Utworzono: {{ new Date(createdAt).toLocaleString() }}, ostatnia modyfikacja: {{ new Date(updatedAt).toLocaleString() }}
                  </p>
                </div>

                <div v-if="!isUnlimited" class="md-layout-item md-small-size-100 md-size-100">
                  <md-field :class="{ 'md-invalid': $v.maxUses.$error }">
                    <label>Ilość użyć</label>
                    <md-input v-model="maxUses" type="text"></md-input>
                    <span class="md-error md-size-100" v-if="!$v.maxUses.required">Ilość użyć jest wymagana</span>
                  </md-field>
                </div>

                <div class="md-layout-item md-size-100 text-right">
                  <md-button
                    :class="`md-raised ${this.confirmDiscountCodeDelete ? 'md-danger' : 'md-accent'}`"
                    type="button"
                    @click="handleDelete()"
                    >{{ confirmDiscountCodeDelete ? "Potwierdź usunięcie" : "Usuń kod rabatowy" }}</md-button
                  >
                  <md-button class="md-raised md-success" @click="handleSubmit()">Zapisz</md-button>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import { required } from "vuelidate/lib/validators";

export default {
  name: "DiscountCodesEdit",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "green",
    },
  },
  data() {
    return {
      code: "",
      discount: "",
      isPercentage: false,
      uses: 0,
      maxUses: "",
      isUnlimited: false,
      createdAt: "",
      updatedAt: "",
      submitStatus: null,
      confirmDiscountCodeDelete: false,
    };
  },
  mounted() {
    this.getDiscountCodeById();
  },
  methods: {
    async getDiscountCodeById() {
      return await this.$axios
        .get(`/discount-codes/${this.$route.params.id}`)
        .then((res) => {
          const discountCode = res.data;

          this.code = discountCode.code;
          this.discount = discountCode.discount;
          this.isPercentage = discountCode.isPercentage;
          this.uses = discountCode.uses;
          this.maxUses = discountCode.maxUses;
          this.isUnlimited = discountCode.isUnlimited;
          this.createdAt = discountCode.createdAt;
          this.updatedAt = discountCode.updatedAt;
        })
        .catch((error) => {
          this.clearFields();
        });
    },
    handleDelete() {
      if (this.confirmDiscountCodeDelete) {
        this.deleteDiscountCode();
      } else {
        this.confirmDiscountCodeDelete = true;
      }
    },
    async deleteDiscountCode() {
      return this.$axios
        .delete(`/discount-codes/${this.$route.params.id}`)
        .then((res) => {
          this.clearFields();
          this.$router.push({ path: "/discount-codes" });
        })
        .catch((error) => {
          this.clearFields();
        });
    },
    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        this.editDiscountCode();
      }
    },
    async editDiscountCode() {
      const discountCode = {
        code: this.code,
        discount: Number(this.discount),
        isPercentage: this.isPercentage,
        maxUses: this.isUnlimited ? 0 : Number(this.maxUses),
        isUnlimited: this.isUnlimited,
      };

      return await this.$axios
        .patch(`/discount-codes/${this.$route.params.id}`, discountCode)
        .then(() => {
          this.$router.push({ path: "/discount-codes" });
          this.submitStatus = "OK";
        })
        .catch((err) => {
          this.submitStatus = "ERROR";
        });
    },
    clearFields() {
      this.code = "";
      this.discount = "";
      this.isPercentage = false;
      this.maxUses = "";
      this.isUnlimited = false;
    },
  },
  validations: {
    code: {
      required,
    },
    discount: {
      required,
    },
    isPercentage: {},
    isUnlimited: {},
    maxUses: {
      required: requiredIf((vm) => !vm.isUnlimited),
    },
  },
};
</script>

<style scoped></style>
