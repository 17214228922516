<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-80">
        <form @submit.prevent="submit">
          <md-card>
            <md-card-header :data-background-color="dataBackgroundColor">
              <h4 class="title">Edytuj użytkownika</h4>
              <p class="category"></p>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field :class="{ 'md-invalid': $v.username.$error }">
                    <label>Nowa nazwa użytkownika</label>
                    <md-input v-model="username" type="text"></md-input>
                    <span
                      class="md-error md-size-100"
                      v-if="!$v.username.required"
                      >Nazwa użytkownika wymagana</span
                    >
                  </md-field>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field :class="{ 'md-invalid': $v.emailadress.$error }">
                    <label>Nowy adres Email</label>
                    <md-input v-model="emailadress" type="email"></md-input>
                    <span class="md-error" v-if="!$v.emailadress.required"
                      >Email wymagany</span
                    >
                    <span class="md-error" v-if="!$v.emailadress.email"
                      >Email nieprawidłowy</span
                    >
                  </md-field>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field :class="{ 'md-invalid': $v.password.$error }">
                    <label>Nowe hasło</label>
                    <md-input v-model="password" type="password"></md-input>
                    <span
                      class="md-error md-size-100"
                      v-if="!$v.password.required"
                      >Hasło wymagane</span
                    >
                    <span
                      class="md-error md-size-100"
                      v-if="!$v.password.minLength"
                      >Hasło za krótkie</span
                    >
                    <span
                      class="md-error md-size-100"
                      v-if="
                        $v.password.required &&
                        $v.password.minLength &&
                        !$v.password.hasSpecialChar
                      "
                      >Hasło musi zawierać conajmniej jeden znak specjalny</span
                    >
                  </md-field>
                </div>
                <div class="md-layout-item md-size-100 text-right">
                  <md-button class="md-raised md-success" type="submit"
                    >Zarejestruj</md-button
                  >
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength, email } from "vuelidate/lib/validators";

const hasSpecialChar = (value) => {
  const specialCharRegex = RegExp(/^(?=.*?[#?!@$%^&*-])/g);
  return specialCharRegex.test(value);
};
export default {
  name: "users-edit",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "green",
    },
  },
  data() {
    return {
      username: null,
      password: null,
      emailadress: null,
      submitStatus: null,
    };
  },
  methods: {
    // submit() {
    //   this.$v.$touch()
    //   if (this.$v.$invalid) {
    //     this.submitStatus = 'ERROR'
    //   } else {
    //     // do your submit logic here
    //     this.submitStatus = 'PENDING'
    //     this.registerUser();
    //   }
    //   },
    // async registerUser () {
    //   const user = {
    //       email: this.emailadress,
    //       password: this.password,
    //       name: this.username,
    //   }
    //   return await axios.post('http://localhost:8080/auth/users', user)
    //   .then(results => {
    //     console.log(results)
    //     this.clearFields();
    //     this.$router.push({path: '/users'})
    //     this.submitStatus = 'OK'
    //   })
    //   .catch(err => {
    //     console.log(err)
    //   })
    // },
    // clearFields() {
    //   this.username = '';
    //   this.emailadress = '';
    //   this.password = '';
    // }
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
      minLength: minLength(8),
      hasSpecialChar,
    },
    emailadress: {
      required,
      email,
    },
  },
};
</script>

<style scoped></style>
