import { router } from "../router";
import { userService } from "@/_services/user.service";

const user = JSON.parse(localStorage.getItem("userData"));
const state = user ? { status: { loggedIn: true }, user } : { status: {}, user: null };

const actions = {
  login({ dispatch, commit }, { username, password }) {
    commit("loginRequest", { username });

    userService.login(username, password).then(
      (user) => {
        commit("loginSuccess", user);
        router.push("/");
      },
      (error) => {
        commit("loginFailure", error);
      }
    );
  },
  logout({ commit }) {
    userService.logout();
    commit("logout");
  },
};

const mutations = {
  loginRequest(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
};
