import Vue from 'vue';
import Vuex from 'vuex';

import { account } from './account.module';
Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        account,
    }
})

/*
* Based on: https://github.com/cornflourblue/vue-vuex-registration-login-example
* License: https://github.com/cornflourblue/vue-vuex-registration-login-example/blob/master/LICENSE
* */