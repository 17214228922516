<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Szczegóły zamówienia #{{ this.$route.params.id }}</h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-size-100"><b>Status płatności:</b> {{ data.paymentStatus }}</div>
              <div class="md-layout-item md-size-100"><b>Kwota:</b> {{ data.totalPrice }} {{ data.currency.toUpperCase() }}</div>
              <div class="md-layout-item md-size-100"><b>Sesja Stripe:</b> {{ data.sessionId }}</div>
              <hr />
              <div class="md-layout-item md-size-100"><b>Utworzono:</b> {{ new Date(data.createdAt).toLocaleString() }}</div>
              <div class="md-layout-item md-size-100"><b>Aktualizacja:</b> {{ new Date(data.updatedAt).toLocaleString() || "-" }}</div>
            </div>
          </md-card-content>
        </md-card>

        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Dane zamawiającego</h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout" v-if="!!data.user">
              <div class="md-layout-item md-size-100"><b>ID:</b> {{ data.user.id }}</div>
              <div class="md-layout-item md-size-100"><b>Email:</b> {{ data.user.cognitoId }}</div>
              <div class="md-layout-item md-size-100"><b>Imię:</b> {{ data.user.firstName }}</div>
              <div class="md-layout-item md-size-100"><b>Nazwisko:</b> {{ data.user.lastName }}</div>
              <div class="md-layout-item md-size-100"><b>Telefon:</b> {{ data.user.phoneNumber || "-" }}</div>
              <div class="md-layout-item md-size-100">
                <b>Adres:</b> {{ data.user.address || "-" }} {{ data.user.postcode }} {{ data.user.city }}
              </div>
            </div>
            <div class="md-layout" v-if="!data.user">
              <div class="md-layout-item md-size-100">Brak danych</div>
            </div>
          </md-card-content>
        </md-card>

        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Przedmioty zamówienia</h4>
          </md-card-header>
          <md-card-content>
            <md-table v-model="data.items">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
                <md-table-cell md-label="Nazwa">{{ item.details.name }}</md-table-cell>
                <md-table-cell md-label="Sprzedawca">{{ item.details.user.cognitoId }}</md-table-cell>
                <md-table-cell md-label="Cena">{{ item.price }} {{ data.currency.toUpperCase() }}</md-table-cell>
                <md-table-cell md-label="Ilość">{{ item.quantity }}</md-table-cell>
                <md-table-cell md-label="Suma">{{ item.price * item.quantity }} {{ data.currency.toUpperCase() }}</md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
      <!-- <div class="md-layout-item md-size-100">
        
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderDetails",
  data() {
    return {
      data: null,
    };
  },
  mounted() {
    this.getContactDetails();
  },
  methods: {
    async getContactDetails() {
      return await this.$axios
        .get(`/orders/admin/${this.$route.params.id}`)
        .then((res) => {
          this.data = res.data;
        })
        .catch((error) => {
          this.data = null;
        });
    },
  },
};
</script>
