<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Kody rabatowe</h4>
          </md-card-header>
          <md-card-content>
            <router-link to="/discount-codes/add">
              <md-button class="md-info">Dodaj kod rabatowy</md-button>
            </router-link>
          </md-card-content>
          <md-card-content>
            <md-table v-model="discountCodes">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
                <md-table-cell md-label="Kod">{{ item.code }}</md-table-cell>
                <md-table-cell md-label="Wartość rabatu">{{ item.discount }} {{ item.isPercentage ? "%" : "PLN" }}</md-table-cell>
                <md-table-cell md-label="Użycia">{{ item.uses }}</md-table-cell>
                <md-table-cell md-label="Limit użyć">{{ item.isUnlimited ? "bez limitu" : item.maxUses }}</md-table-cell>
                <md-table-cell md-label="Akcje">
                  <router-link :to="`/discount-codes/${item.id}`">
                    <md-button class="md-primary">Edytuj</md-button>
                  </router-link>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
      <!-- <div class="md-layout-item md-size-100">
        
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "DiscountCodesList",
  data() {
    return {
      discountCodes: [],
    };
  },
  mounted() {
    this.getDiscountCodes();
  },
  methods: {
    async getDiscountCodes() {
      return await this.$axios
        .get(`/discount-codes`)
        .then((res) => {
          this.discountCodes = res.data;
        })
        .catch((error) => {
          this.discountCodes = [];
        });
    },
  },
};
</script>
