<template>
  <ul class="nav nav-mobile-menu">
    <li>
      <a href="#/user" data-toggle="dropdown" class="dropdown-toggle"
        ><i class="material-icons">person</i>
        <p>Profile</p></a
      >
    </li>
  </ul>
</template>
<script>
export default {};
</script>
