<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-80">
        <form @submit.prevent="submit">
          <md-card>
            <md-card-header :data-background-color="dataBackgroundColor">
              <h4 class="title">Edytuj kategorię "{{ this.name }}"</h4>
              <p class="category"></p>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field :class="{ 'md-invalid': $v.name.$error }">
                    <label>Nazwa</label>
                    <md-input v-model="name" type="text"></md-input>
                    <span class="md-error md-size-100" v-if="!$v.name.required">Nazwa kategorii jest wymagana</span>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field :class="{ 'md-invalid': $v.description.$error }">
                    <label>Opis kategorii</label>
                    <md-textarea v-model="description" type="text"></md-textarea>
                    <span class="md-error md-size-100" v-if="!$v.description.required">Opis kategorii jest wymagany</span>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field :class="{ 'md-invalid': $v.slug.$error }">
                    <label>Slug</label>
                    <md-input v-model="slug" type="text"></md-input>
                    <span class="md-error md-size-100" v-if="!$v.slug.required">Slug jest wymagany</span>
                  </md-field>
                </div>

                <div v-if="!this.iconUrl" class="md-layout-item md-size-100">
                  <md-field :class="{ 'md-invalid': $v.file.$error }">
                    <label>Ikona</label>
                    <md-file v-model="file" />
                  </md-field>
                </div>

                <div class="md-layout-item md-size-100">
                  <img v-if="!!this.iconUrl" :src="this.iconUrl" alt="icon" class="categories-edit__icon" />
                  <md-button v-if="!!this.iconUrl" :class="`md-raised ${this.deleteIcon ? '' : 'md-danger'}`" @click="toggleDeleteIcon()">
                    {{ this.deleteIcon ? "Anuluj usuwanie ikony" : "Usuń ikonę" }}
                  </md-button>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field>
                    <label for="parent">Kategoria nadrzędna</label>
                    <md-select v-model="parent" name="parent" id="parent">
                      <md-option value="0">-- Brak --</md-option>
                      <md-option
                        v-for="item in categories.filter((item) => String(item.id) !== String(this.$route.params.id))"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>

                <div class="md-layout-item md-size-100 text-right">
                  <md-button
                    :class="`md-raised ${this.confirmCategoryDelete ? 'md-danger' : 'md-accent'}`"
                    type="button"
                    @click="handleDelete()"
                    >{{ confirmCategoryDelete ? "Potwierdź usunięcie" : "Usuń kategorię" }}</md-button
                  >
                  <md-button class="md-raised md-success" type="submit" @click="handleSubmit()">Zapisz</md-button>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CategoriesEdit",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "green",
    },
  },
  data() {
    return {
      name: "",
      description: "",
      initialSlug: "",
      slug: "",
      file: undefined,
      deleteIcon: false,
      iconUrl: null,
      parent: "0",
      submitStatus: null,
      categories: [],
      confirmCategoryDelete: false,
    };
  },
  mounted() {
    this.getCategoryById();
    this.getCategories();
  },
  methods: {
    async getCategoryById() {
      return await axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/categories/${this.$route.params.id}`)
        .then((res) => {
          const category = res.data;

          this.name = category.name;
          this.description = category.description || "";
          this.initialSlug = category.slug;
          this.slug = category.slug;
          this.parent = category?.parent?.id || "0";
          this.iconUrl = category.iconUrl;
        })
        .catch((error) => {
          this.clearFields();
        });
    },
    async getCategories() {
      return await axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/categories/all-categories`)
        .then((res) => {
          this.categories = res.data;
        })
        .catch((error) => {
          this.categories = [];
        });
    },
    handleDelete() {
      if (this.confirmCategoryDelete) {
        this.deleteCategory();
      } else {
        this.confirmCategoryDelete = true;
      }
    },
    async deleteCategory() {
      return axios
        .delete(`${process.env.VUE_APP_API_BASE_URL}/categories/${this.$route.params.id}`)
        .then((res) => {
          this.clearFields();
          this.$router.push({ path: "/categories" });
        })
        .catch((error) => {
          this.clearFields();
        });
    },
    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // do your submit logic here
        this.submitStatus = "PENDING";
        this.editCategory();
      }
    },
    async editCategory() {
      const category = {
        name: this.name,
        description: this.description,
        slug: this.initialSlug !== this.slug ? this.slug : undefined,
        file: this.deleteIcon ? null : this.file,
        parentId: String(this.parent) || "0",
      };
      return await this.$axios
        .patch(`/categories/edit/${this.$route.params.id}`, category, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((results) => {
          this.clearFields();
          this.$router.push({ path: "/categories" });
          this.submitStatus = "OK";
        })
        .catch((err) => {
          this.submitStatus = "ERROR";
        });
    },
    toggleDeleteIcon() {
      this.deleteIcon = !this.deleteIcon;
    },
    clearFields() {
      this.name = "";
      this.description = "";
      this.file = null;
      this.parent = "0";
      this.deleteIcon = false;
      this.iconUrl = null;
      this.confirmCategoryDelete = false;
    },
  },
  validations: {
    name: {
      required,
    },
    description: {
      required,
    },
    slug: {
      required,
    },
    file: {},
    parent: {},
  },
};
</script>

<style scoped>
.categories-edit__icon {
  width: fit-content;
  height: 80px;
  object-fit: contain;
}
</style>
