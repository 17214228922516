<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>

    <side-bar :sidebar-item-color="sidebarBackground" :sidebar-background-image="sidebarBackgroundImage">
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/orders">
        <md-icon>local_shipping</md-icon>
        <p>Zamówienia</p>
      </sidebar-link>
      <sidebar-link to="/categories">
        <md-icon>category</md-icon>
        <p>Kategorie</p>
      </sidebar-link>
      <sidebar-link to="/discount-codes">
        <md-icon>sell</md-icon>
        <p>Kody rabatowe</p>
      </sidebar-link>
      <sidebar-link to="/newsletter">
        <md-icon>mail</md-icon>
        <p>Newsletter</p>
      </sidebar-link>
      <sidebar-link to="/contact">
        <md-icon>chat</md-icon>
        <p>Wiadomości - kontakt</p>
      </sidebar-link>
      <sidebar-link to="/cooperation">
        <md-icon>contact_mail</md-icon>
        <p>Wiadomości - współpraca</p>
      </sidebar-link>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <fixed-plugin :color.sync="sidebarBackground" :image.sync="sidebarBackgroundImage"> </fixed-plugin>

      <dashboard-content> </dashboard-content>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TopNavbar from "./TopNavbar.vue";
// import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "@/pages/Layout/MobileMenu.vue";
import FixedPlugin from "./Extra/FixedPlugin.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    // ContentFooter,
    MobileMenu,
    FixedPlugin,
  },
  mounted() {
    this.loadAxios();
  },
  data() {
    return {
      sidebarBackground: "green",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),
    };
  },
  methods: {
    ...mapActions("account", ["logout"]),
    handleLogout() {
      this.logout();
      this.$router.push({ path: "/login" });
    },
    loadAxios() {
      this.$axios.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
        return config;
      });
      this.$axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response.status === 401) {
            this.logout();
            this.$router.push({ path: "/login" });
          }
          return Promise.reject(error);
        }
      );
    },
  },
};
</script>
