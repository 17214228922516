<template>
  <div class="content">
    <div class="md-layout">
      <!-- <div class="md-layout-item md-size-100">
        <router-link to="/categories/add">
          <md-button class="md-info">Dodaj kategorię</md-button>
        </router-link>
      </div> -->
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Kategorie</h4>
          </md-card-header>
          <md-card-content>
            <router-link to="/categories/add">
              <md-button class="md-info">Dodaj kategorię</md-button>
            </router-link>
          </md-card-content>
          <md-card-content>
            <md-table v-model="categories">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
                <md-table-cell md-label="Nazwa">{{ item.name }}</md-table-cell>
                <md-table-cell md-label="Slug">{{ item.slug }}</md-table-cell>
                <md-table-cell md-label="Ikona">
                  <img v-if="!!item.iconUrl" :src="item.iconUrl" alt="icon" class="categories-table__icon" />
                  <span v-else>-</span>
                </md-table-cell>
                <md-table-cell md-label="Akcje">
                  <router-link :to="`/categories/${item.id}`">
                    <md-button class="md-primary"> Edytuj </md-button>
                  </router-link>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
      <!-- <div class="md-layout-item md-size-100">
        
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CategoriesList",
  data() {
    return {
      categories: [],
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      return await this.$axios
        .get(`/categories/all-categories`)
        .then((res) => {
          this.categories = res.data;
        })
        .catch((error) => {
          this.categories = [];
        });
    },
  },
};
</script>

<style scoped>
.categories-table__icon {
  width: fit-content;
  height: 30px;
  object-fit: contain;
}
</style>
