<template>
  <section class="content">
    <div class="md-card login-card">
      <div class="md-card-header">
        <h2 class="title">Panel administracyjny</h2>
        <p class="category">Zaloguj się</p>
      </div>
      <div class="md-card-content">
        <div class="md-layout-item md-small-size-100">
          <h4 class="title">Nazwa użytkownika</h4>
          <div class="md-field">
            <input v-model="username" name="username" type="text" id="md-input-username" class="md-input" />
          </div>
        </div>
        <div class="md-layout-item md-small-size-100">
          <h4 class="title">Hasło</h4>
          <div class="md-field">
            <input v-model="password" type="password" name="password" id="md-input-password" class="md-input" />
          </div>
        </div>
        <div class="md-layout-item md-size-100 text-right">
          <button type="button" class="md-button md-raised md-success md-theme-default" @click="handleSubmit">
            <div class="md-ripple">
              <div class="md-button-content">Zaloguj się</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
    };
  },
  computed: {
    ...mapState("account", ["status"]),
  },
  created() {
    this.logout();
  },
  methods: {
    ...mapActions("account", ["login", "logout"]),
    handleSubmit(e) {
      this.submitted = true;
      const { username, password } = this;
      if (username && password) {
        this.login({ username, password });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/md/_variables.scss";

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.md-card-content {
  padding-top: 30px;
}

.login-card {
  max-width: 50vw;
  min-width: 300px;
  margin-inline: auto;

  .md-card-header {
    background-color: $green;
  }
}
</style>
