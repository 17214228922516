<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-100">
        <edit-profile-form data-background-color="green"> </edit-profile-form>
      </div>
      <div class="md-layout-item md-size-100">
        <md-button class="md-raised" type="submit" @click="handleLogout">Wyloguj się</md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { EditProfileForm } from "@/pages";

export default {
  components: {
    EditProfileForm,
  },
  methods: {
    ...mapActions("account", ["logout"]),
    handleLogout() {
      this.logout();
      this.$router.push({ path: "/login" });
    },
  },
};
</script>
