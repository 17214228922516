<template>
  <div>
    <md-table v-model="users" v-if="users.length && !isLoading" :table-header-color="tableHeaderColor">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Nazwa użytkownika">{{ item.Username }}</md-table-cell>
        <md-table-cell md-label="Status konta">{{ item.UserStatus }}</md-table-cell>
        <md-table-cell md-label="Data stworzenia">{{ item.UserCreateDate }}</md-table-cell>
        <md-table-cell md-label="Data modyfikacji">{{ item.UserLastModifiedDate }}</md-table-cell>
        <md-table-cell md-label="Usuń">
          <md-button @click="deleteUser(item)" class="md-button md-just-icon md-simple md-danger md-theme-default">
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
        <md-table-cell md-label="Edytuj">
          <router-link :to="{ name: 'Edytuj użytkownika', params: { id: item.Username } }">
            <md-button class="md-just-icon md-simple md-primary">
              <md-icon>edit</md-icon>
            </md-button>
          </router-link>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <div class="text-center" v-else-if="isLoading">
      <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "users-table",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selected: [],
      users: [],
      isLoading: false,
    };
  },
  mounted() {
    this.isLoading = true;

    // this.fetchData(process.env.VUE_APP_USERS_URL)
    //   .then(({ data: { Users } }) => {
    //     this.users = Users;
    //     this.isLoading = false;
    //   })
    //   .catch((error) => {
    //     this.isLoading = false;
    //   });
  },
  methods: {
    async fetchData(url) {
      return await axios.get(url);
    },
    async deleteData(url, data, headers = {}) {
      return await axios.delete(url, { data: data, headers: headers });
    },
    async deleteUser({ Username }) {
      const userToDelete = {
        Username,
      };
      // this.deleteData(process.env.VUE_APP_USERS_URL, Username)
      // .then(response => console.log(response))
      // .catch(err => console.log(err))
    },
  },
};
</script>
