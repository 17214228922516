import { authHeader } from "@/authHeader";
import axios from "axios";

export const userService = {
  login,
  logout,
  getAllUsers,
  getUser,
  updateUser,
  deleteUser,
};

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ name: username, password }),
  };
  return fetch(`${process.env.VUE_APP_API_BASE_URL}/auth/login`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      if (res.accessToken.jwtToken) {
        localStorage.setItem("accessToken", res.accessToken.jwtToken);

        return axios.get(`${process.env.VUE_APP_API_BASE_URL}/users/me`, {
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${res.accessToken.jwtToken}` },
        });
      }

      return null;
    })
    .then((userRes) => {
      const user = userRes?.data || null;
      if (user) {
        localStorage.setItem("userData", JSON.stringify(user));
      }

      return user;
    });
}

function logout() {
  localStorage.removeItem("userData");
  localStorage.removeItem("accessToken");
}

function getAllUsers() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${process.env.VUE_APP_API_BASE_URL}/auth/users`, requestOptions).then(handleResponse);
}

function getUser(name) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${process.env.VUE_APP_API_BASE_URL}/auth/users/${name}`, requestOptions).then(handleResponse);
}

// updateDTO: name, firstName, password, lastName
function updateUser(user) {
  const requestOptions = {
    method: "PUT",
    body: user,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${process.env.VUE_APP_API_BASE_URL}/auth/users`, requestOptions).then(handleResponse);
}

// deleteDTO : name, email
function deleteUser(user) {
  const requestOptions = {
    method: "DELETE",
    body: user,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${process.env.VUE_APP_API_BASE_URL}/auth/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
