<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-80">
        <form @submit.prevent="submit">
          <md-card>
            <md-card-header :data-background-color="dataBackgroundColor">
              <h4 class="title">Dodaj kod rabatowy</h4>
              <p class="category"></p>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field :class="{ 'md-invalid': $v.code.$error }">
                    <label>Kod</label>
                    <md-input v-model="code" type="text"></md-input>
                    <span class="md-error md-size-100" v-if="!$v.code.required">Kod rabatowy jest wymagany</span>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field :class="{ 'md-invalid': $v.discount.$error }">
                    <label>Wartość rabatu</label>
                    <md-input v-model="discount" type="text"></md-input>
                    <span class="md-error md-size-100" v-if="!$v.discount.required">Wartość rabatu jest wymagana</span>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-checkbox v-model="isPercentage">Wartość procentowa</md-checkbox>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-checkbox v-model="isUnlimited">Nieskończone użycia?</md-checkbox>
                </div>

                <div v-if="!isUnlimited" class="md-layout-item md-small-size-100 md-size-100">
                  <md-field :class="{ 'md-invalid': $v.maxUses.$error }">
                    <label>Ilość użyć</label>
                    <md-input v-model="maxUses" type="text"></md-input>
                    <span class="md-error md-size-100" v-if="!$v.maxUses.required">Ilość użyć jest wymagana</span>
                  </md-field>
                </div>

                <div class="md-layout-item md-size-100 text-right">
                  <md-button class="md-raised md-success" type="submit" @click="handleSubmit()">Utwórz</md-button>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import { required } from "vuelidate/lib/validators";

export default {
  name: "DiscountCodesAdd",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "green",
    },
  },
  data() {
    return {
      code: "",
      discount: "",
      isPercentage: false,
      maxUses: "",
      isUnlimited: false,
      submitStatus: null,
    };
  },
  methods: {
    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        this.createDiscountCode();
      }
    },
    async createDiscountCode() {
      const discountCode = {
        code: this.code,
        discount: Number(this.discount),
        isPercentage: this.isPercentage,
        maxUses: this.isUnlimited ? 0 : Number(this.maxUses),
        isUnlimited: this.isUnlimited,
      };

      return await this.$axios
        .post(`/discount-codes/`, discountCode)
        .then(() => {
          this.clearFields();
          this.$router.push({ path: "/discount-codes" });
          this.submitStatus = "OK";
        })
        .catch((err) => {
          this.submitStatus = "ERROR";
        });
    },
    clearFields() {
      this.code = "";
      this.discount = "";
      this.isPercentage = false;
      this.maxUses = "";
      this.isUnlimited = false;
    },
  },
  validations: {
    code: {
      required,
    },
    discount: {
      required,
    },
    isPercentage: {},
    isUnlimited: {},
    maxUses: {
      required: requiredIf((vm) => !vm.isUnlimited),
    },
  },
};
</script>

<style scoped></style>
